import { useState } from "react";
import axios from "axios";
import HOTSKUCSS from "../css/hotsku.module.css";

function HOTSKU() {
  const [inputSKU, setInputSKU] = useState({
    sku1: "",
    sku2: "",
    sku3: "",
  });

  const [isSubmitClicked, setIsSubmitClicked] = useState({
    monitor1: false,
    monitor2: false,
    monitor3: false,
  });

  const [showRemoveInfo, setShowRemoveInfo] = useState({
    monitor1: "",
    monitor2: "",
    monitor3: "",
  });

  const [isDeleteClicked, setIsDeleteClicked] = useState({
    monitor1: false,
    monitor2: false,
    monitor3: false,
  });

  const [showInfo, setShowInfo] = useState({
    monitor1: "",
    monitor2: "",
    monitor3: "",
  });

  const handleSubmitHOTSku = async (e) => {
    e.preventDefault();

    const type = e.target.name;
    let sku =
      type === "monitor1"
        ? inputSKU.sku1
        : type === "monitor2"
        ? inputSKU.sku2
        : inputSKU.sku3;

    if (type === "monitor1")
      setIsSubmitClicked({
        monitor1: true,
      });
    if (type === "monitor2")
      setIsSubmitClicked({
        monitor2: true,
      });
    if (type === "monitor3")
      setIsSubmitClicked({
        monitor3: true,
      });

    setTimeout(() => {
      try {
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `/api/hotskucreate?type=${type}`,
          data: {
            sku: sku,
          },
        };

        axios(config)
          .then(() => {
            if (type === "monitor1")
              setShowInfo({
                monitor1: "sent",
              });
            if (type === "monitor2")
              setShowInfo({
                monitor2: "sent",
              });
            if (type === "monitor3")
              setShowInfo({
                monitor3: "sent",
              });
          })
          .catch(() => {
            if (type === "monitor1")
              setShowInfo({
                monitor1: "error",
              });
            if (type === "monitor2")
              setShowInfo({
                monitor2: "error",
              });
            if (type === "monitor3")
              setShowInfo({
                monitor3: "error",
              });
          });
      } catch (error) {
        if (type === "monitor1")
          setShowInfo({
            monitor1: "error",
          });
        if (type === "monitor2")
          setShowInfo({
            monitor2: "error",
          });
        if (type === "monitor3")
          setShowInfo({
            monitor3: "error",
          });
      }

      if (type === "monitor1")
        setIsSubmitClicked({
          monitor1: false,
        });
      if (type === "monitor2")
        setIsSubmitClicked({
          monitor2: false,
        });
      if (type === "monitor3")
        setIsSubmitClicked({
          monitor3: false,
        });
    }, 1000);
  };

  const handleDeleteHOTSku = (e) => {
    e.preventDefault();

    const type = e.target.name;
    let sku =
      type === "monitor1"
        ? inputSKU.sku1
        : type === "monitor2"
        ? inputSKU.sku2
        : inputSKU.sku3;

    if (type === "monitor1")
      setIsDeleteClicked({
        monitor1: true,
      });
    if (type === "monitor2")
      setIsDeleteClicked({
        monitor2: true,
      });
    if (type === "monitor3")
      setIsDeleteClicked({
        monitor3: true,
      });

    setTimeout(() => {
      try {
        const config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `/api/hotskudelete?type=${type}`,
          data: {
            sku: sku,
          },
        };

        axios(config)
          .then(() => {
            if (type === "monitor1")
              setShowRemoveInfo({
                monitor1: "sent",
              });
            if (type === "monitor2")
              setShowRemoveInfo({
                monitor2: "sent",
              });
            if (type === "monitor3")
              setShowRemoveInfo({
                monitor3: "sent",
              });
          })
          .catch(() => {
            if (type === "monitor1")
              setShowRemoveInfo({
                monitor1: "error",
              });
            if (type === "monitor2")
              setShowRemoveInfo({
                monitor2: "error",
              });
            if (type === "monitor3")
              setShowRemoveInfo({
                monitor3: "error",
              });
          });
      } catch (error) {
        if (type === "monitor1")
          setShowRemoveInfo({
            monitor1: "error",
          });
        if (type === "monitor2")
          setShowRemoveInfo({
            monitor2: "error",
          });
        if (type === "monitor3")
          setShowRemoveInfo({
            monitor3: "error",
          });
      }

      if (type === "monitor1")
        setIsDeleteClicked({
          monitor1: false,
        });
      if (type === "monitor2")
        setIsDeleteClicked({
          monitor2: false,
        });
      if (type === "monitor3")
        setIsDeleteClicked({
          monitor3: false,
        });
    }, 1000);
  };

  return (
    <div className={HOTSKUCSS["root-hotsku-shoes"]}>
      <form
        className={HOTSKUCSS["form-container"]}
        onSubmit={handleSubmitHOTSku}
        method="post"
        name="monitor1"
      >
        <label htmlFor="inputName">Enter the SKU (ASICS)</label>
        <input
          type="text"
          name="inputSKU1"
          id="inputSKU"
          onChange={(e) =>
            setInputSKU({
              sku1: e.target.value,
              sku2: inputSKU.sku2,
              sku3: inputSKU.sku3,
            })
          }
        />
        <button
          className={isSubmitClicked.monitor1 ? HOTSKUCSS["spin"] : ""}
          type="submit"
        >
          {showInfo.monitor1 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU added successfully</p>
            </div>
          )}
          {showInfo.monitor1 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Add
          <span className={HOTSKUCSS["spinner"]}></span>
        </button>
        <button
          className={isDeleteClicked.monitor1 ? HOTSKUCSS["spin"] : ""}
          onClick={handleDeleteHOTSku}
          type="button"
          name="monitor1"
        >
          {showRemoveInfo.monitor1 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU deleted successfully</p>
            </div>
          )}
          {showRemoveInfo.monitor1 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Remove
        </button>
      </form>

      <form
        className={HOTSKUCSS["form-container"]}
        onSubmit={handleSubmitHOTSku}
        method="post"
        name="monitor2"
      >
        <label htmlFor="inputName">Enter the SKU (JD)</label>
        <input
          type="text"
          name="inputSKU2"
          id="inputSKU"
          onChange={(e) =>
            setInputSKU({
              sku1: inputSKU.sku1,
              sku2: e.target.value,
              sku3: inputSKU.sku3,
            })
          }
        />
        <button
          className={isSubmitClicked.monitor2 ? HOTSKUCSS["spin"] : ""}
          type="submit"
        >
          {showInfo.monitor2 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU added successfully</p>
            </div>
          )}
          {showInfo.monitor2 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Add
          <span className={HOTSKUCSS["spinner"]}></span>
        </button>
        <button
          className={isDeleteClicked.monitor2 ? HOTSKUCSS["spin"] : ""}
          onClick={handleDeleteHOTSku}
          type="button"
          name="monitor2"
        >
          {showRemoveInfo.monitor2 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU deleted successfully</p>
            </div>
          )}
          {showRemoveInfo.monitor2 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Remove
        </button>
      </form>

      <form
        className={HOTSKUCSS["form-container"]}
        onSubmit={handleSubmitHOTSku}
        method="post"
        name="monitor3"
      >
        <label htmlFor="inputName">Enter the SKU (FNL)</label>
        <input
          type="text"
          name="inputSKU3"
          id="inputSKU"
          onChange={(e) =>
            setInputSKU({
              sku1: inputSKU.sku1,
              sku2: inputSKU.sku2,
              sku3: e.target.value,
            })
          }
        />
        <button
          className={isSubmitClicked.monitor3 ? HOTSKUCSS["spin"] : ""}
          type="submit"
        >
          {showInfo.monitor3 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU added successfully</p>
            </div>
          )}
          {showInfo.monitor3 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Add
          <span className={HOTSKUCSS["spinner"]}></span>
        </button>
        <button
          className={isDeleteClicked.monitor3 ? HOTSKUCSS["spin"] : ""}
          onClick={handleDeleteHOTSku}
          type="button"
          name="monitor3"
        >
          {showRemoveInfo.monitor3 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU deleted successfully</p>
            </div>
          )}
          {showRemoveInfo.monitor3 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Remove
        </button>
      </form>
    </div>
  );
}

export default HOTSKU;
