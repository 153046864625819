import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import ASICSCSS from "../css/asics.module.css";
import editImg from "../assets/edit.svg";
import cloudUploadImg from "../assets/cloud-upload.svg";
import deleteImg from "../assets/delete.svg";
import menuDotsImg from "../assets/menu-dots.svg";

function ASICS() {
  // Region list: US
  const [restockInputValue, setRestockInputValue] = useState({
    US: [],
  });

  const versionRegionList = "v0.0.1";
  const [regionList, setRegionList] = useState([
    { region: "US", folded: false },
  ]);
  const [showWebhooks, setShowWebhooks] = useState([]);
  const [uploadMenu, setUploadMenu] = useState(false);
  const [labelInputValue, setLabelInputValue] = useState("");
  const [webhookURLInputValue, setWebhookURLInputValue] = useState("");
  const [reloadWebhooks, setReloadWebhooks] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const region = e.currentTarget.name.split("-")[0];
    const status = e.currentTarget.name.split("-")[1];
    const action = e.currentTarget.name.split("-")[2];
    const label = e.currentTarget.id;
    let data;

    switch (status) {
      case "RESTOCK":
        data = restockInputValue[region].find((el) => el.label === label);
        break;

      default:
        break;
    }

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/asicswebhooks?region=${region}&category=shoes`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action,
          status,
          data: { label: data.label, value: data.value },
        },
      };

      axios(config)
        .then((res) => {
          setReloadWebhooks(!reloadWebhooks);
        })
        .catch(() => {});
    } catch (error) {}
  };

  const handleNewWebhookSubmit = (e) => {
    e.preventDefault();

    const label = labelInputValue;
    const webhookURL = webhookURLInputValue;
    const region = e.target[2].value;
    const status = e.target[3].value;

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/asicswebhooks?region=${region}&category=shoes`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action: "INSERT",
          status,
          data: { label: label, value: webhookURL },
        },
      };

      axios(config)
        .then((res) => {
          setLabelInputValue("");
          setWebhookURLInputValue("");
          setReloadWebhooks(!reloadWebhooks);
          setUploadMenu(false);
        })
        .catch(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setShowWebhooks([]);

    const newRestockInputValue = { ...restockInputValue };

    regionList.forEach((el, index) => {
      setTimeout(() => {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `/api/asicswebhooks?region=${el.region}&category=shoes`,
        };

        axios(config)
          .then((res) => {
            console.log(res.data);

            newRestockInputValue[el.region] = res.data[0].webhooks.map(
              (webhook) => {
                return {
                  label: webhook.label,
                  value: webhook.value,
                  readOnly: true,
                };
              }
            );

            setRestockInputValue(newRestockInputValue);
          })
          .catch(() => {})
          .finally(() => setShowWebhooks((prevState) => [...prevState, 1]));
      }, index * 100);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadWebhooks]);

  useEffect(() => {
    uploadMenu
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "");
  }, [uploadMenu]);

  const variants = {
    open: { opacity: 1, x: 0, display: "flex" },
    closed: { opacity: 0, x: "0%", display: "none" },
  };

  useEffect(() => {
    const storedRegionList =
      JSON.parse(localStorage.getItem(`asics${versionRegionList}`)) || [];

    if (!storedRegionList.length) {
      localStorage.setItem(
        `asics${versionRegionList}`,
        JSON.stringify(regionList)
      );
    } else {
      setRegionList(storedRegionList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={ASICSCSS["root-asics-shoes"]}>
      <div className={ASICSCSS["region-container"]}>
        {uploadMenu ? (
          <div
            onClick={() => setUploadMenu(false)}
            className={ASICSCSS["first-background"]}
          ></div>
        ) : (
          <></>
        )}

        {showWebhooks.length === regionList.length ? (
          <>
            {regionList.map((el, index) => {
              let title = "unknown";
              switch (el.region) {
                case "US":
                  title = "United States";
                  break;
                default:
                  break;
              }

              return (
                <div className={ASICSCSS["region-box"]} key={index}>
                  <h2
                    className={`${ASICSCSS["region-title"]} ${
                      el.folded ? ASICSCSS["region-title-restock"] : ""
                    }`}
                    onClick={() => {
                      let newRegionList = { ...regionList };
                      const updatedRegionList = regionList.map((e) => {
                        if (e.region === el.region) {
                          return { region: el.region, folded: !e.folded };
                        } else {
                          return e;
                        }
                      });
                      newRegionList = updatedRegionList;
                      setRegionList(newRegionList);

                      localStorage.setItem(
                        `asics${versionRegionList}`,
                        JSON.stringify(newRegionList)
                      );
                    }}
                  >
                    ASICS {title}
                  </h2>

                  <motion.div
                    className={`${ASICSCSS["region-webhook"]}`}
                    animate={el.folded ? "open" : "closed"}
                    variants={variants}
                  >
                    <div className={ASICSCSS["webhook"]}>
                      <h3 className={ASICSCSS["webhook-status"]}>
                        RESTOCK Webhooks
                      </h3>
                      {restockInputValue[el.region].map((webhook, index) => {
                        return (
                          <form
                            className={ASICSCSS["webhook-form"]}
                            key={index}
                            action=""
                            method="post"
                            autoComplete="off"
                          >
                            <div className={ASICSCSS["webhook-grid"]}>
                              <label
                                className={ASICSCSS["webhook-label"]}
                                htmlFor={webhook.label}
                              >
                                {webhook.label}
                              </label>
                              <div className={ASICSCSS["webhook-row"]}>
                                <input
                                  className={ASICSCSS["webhook-input"]}
                                  value={webhook.value}
                                  name={el.region}
                                  type="url"
                                  id={webhook.label}
                                  required
                                  onChange={(e) => {
                                    if (e.target.name === el.region) {
                                      let newRestockInputValue = {
                                        ...restockInputValue,
                                      };
                                      const updatedWebhook = restockInputValue[
                                        el.region
                                      ].map((el) => {
                                        if (el.label === webhook.label) {
                                          return {
                                            label: webhook.label,
                                            value: e.target.value,
                                            readOnly: el.readOnly,
                                          };
                                        } else {
                                          return el;
                                        }
                                      });
                                      newRestockInputValue[el.region] =
                                        updatedWebhook;
                                      setRestockInputValue(
                                        newRestockInputValue
                                      );
                                    }
                                  }}
                                  disabled={
                                    restockInputValue[el.region].find(
                                      (el) => el.label === webhook.label
                                    ).readOnly
                                  }
                                />
                                <button
                                  className={ASICSCSS["webhook-edit-btn"]}
                                  id={webhook.label}
                                  name={el.region}
                                  type="button"
                                  onClick={(e) => {
                                    let newRestockInputValue = {
                                      ...restockInputValue,
                                    };
                                    const updatedWebhook = restockInputValue[
                                      e.currentTarget.name
                                    ].map((el) => {
                                      if (el.label === webhook.label) {
                                        return {
                                          label: webhook.label,
                                          value: webhook.value,
                                          readOnly: !el.readOnly,
                                        };
                                      } else {
                                        return el;
                                      }
                                    });
                                    newRestockInputValue[e.currentTarget.name] =
                                      updatedWebhook;
                                    setRestockInputValue(newRestockInputValue);
                                  }}
                                >
                                  <img
                                    className={ASICSCSS["edit-img"]}
                                    src={editImg}
                                    alt="edit-img"
                                  />
                                </button>

                                <button
                                  className={ASICSCSS["webhook-save-btn"]}
                                  id={webhook.label}
                                  name={el.region + "-RESTOCK-UPDATE"}
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  <img
                                    className={ASICSCSS["cloud-upload-img"]}
                                    src={cloudUploadImg}
                                    alt="cloud-upload-img"
                                  />
                                </button>

                                <button
                                  className={ASICSCSS["webhook-delete-btn"]}
                                  id={webhook.label}
                                  name={el.region + "-RESTOCK-DELETE"}
                                  type="button"
                                  onClick={handleSubmit}
                                >
                                  <img
                                    className={ASICSCSS["delete-img"]}
                                    src={deleteImg}
                                    alt="delete-img"
                                  />
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      })}
                    </div>
                  </motion.div>
                </div>
              );
            })}{" "}
          </>
        ) : (
          <div className={ASICSCSS["access-spin"]}>
            <span className={ASICSCSS["access-spinner"]}></span>
          </div>
        )}

        <div className={ASICSCSS["bottom-container"]}>
          <div className={ASICSCSS["menu-box"]}>
            <div
              onClick={() => setUploadMenu(!uploadMenu)}
              className={ASICSCSS["menu-btn"]}
            >
              <img
                className={ASICSCSS["menu-dots-img"]}
                src={menuDotsImg}
                alt="menu-dots-img"
              />
            </div>
          </div>

          <div
            className={`${ASICSCSS["upload-container"]} ${
              uploadMenu ? ASICSCSS["upload-container-show"] : ""
            }`}
          >
            <h2 className={ASICSCSS["upload-form-title"]} htmlFor="region">
              Enter the new webhook
            </h2>

            <form
              className={ASICSCSS["upload-form"]}
              action=""
              method="post"
              autoComplete="off"
              onSubmit={handleNewWebhookSubmit}
            >
              <input
                className={ASICSCSS["upload-input-name"]}
                value={labelInputValue}
                onChange={(e) => setLabelInputValue(e.target.value)}
                type="text"
                name="label"
                placeholder="Label"
                required
              />
              <input
                className={ASICSCSS["upload-input-webhook"]}
                value={webhookURLInputValue}
                onChange={(e) => setWebhookURLInputValue(e.target.value)}
                type="url"
                name="webhook"
                placeholder="Webhook URL"
                required
              />

              <select
                className={ASICSCSS["upload-select-region"]}
                name="region"
              >
                <option value="US">United States</option>
              </select>

              <select
                className={ASICSCSS["upload-select-status"]}
                name="status"
              >
                <option value="RESTOCK">Restock</option>
              </select>

              <button className={ASICSCSS["upload-submit-btn"]} type="submit">
                Upload
              </button>
              <button
                className={ASICSCSS["upload-cancel-btn"]}
                type="button"
                onClick={() => setUploadMenu(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>

        {uploadMenu ? (
          <div
            onClick={() => setUploadMenu(false)}
            className={ASICSCSS["second-background"]}
          ></div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ASICS;
