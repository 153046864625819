import { useState, useEffect } from "react";
import axios from "axios";
import SkusCSS from "../css/skus.module.css";
import AOS from "aos";
import "aos/dist/aos.css";

function ImageMonitor() {
  const [inputSKU, setInputSKU] = useState({
    sku1: "",
    sku2: "",
    sku4: "",
    allsku: "",
    adidashot1: ""
  });

  const [isSubmitClicked, setIsSubmitClicked] = useState({
    monitor1: false,
    monitor2: false,
    monitor4: false,
    allmonitors: false,
    adidashot1: false
  });

  const [isDeleteClicked, setIsDeleteClicked] = useState({
    monitor1: false,
    monitor2: false,
    monitor4: false,
    allmonitors: false,
    adidashot1: false
  });
  
  const [showInfo, setShowInfo] = useState({
    monitor1: "",
    monitor2: "",
    monitor4: "",
    allmonitors: "",
    adidashot1: ""
  });
  
  const [showRemoveInfo, setShowRemoveInfo] = useState({
    monitor1: "",
    monitor2: "",
    monitor4: "",
    allmonitors: "",
    adidashot1: ""
  });

  const handleSubmit = async e => {
    e.preventDefault();

    const type = e.target.name;
    let sku = type === "monitor1" ? inputSKU.sku1 : type === "monitor2" ? inputSKU.sku2 : type === "allmonitors" ? inputSKU.allsku : inputSKU.sku4;

    if (type === "allmonitors") {
      setIsSubmitClicked({ monitor1: isSubmitClicked.monitor1, monitor2: isSubmitClicked.monitor2, monitor4: isSubmitClicked.monitor4, allmonitors: true });

      setTimeout(() => {
        try {
          const monitors = ["monitor1", "monitor2", "monitor4"];

          const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

          if (sku.includes(' ')) {
            sku = (sku.split(' ')).filter(_ => _ !== '');
          } else {
            sku = [sku];
          }

          (async () => {
            for (const type of monitors) {
              const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: '/api/skus?action=ADD',
                data: {
                  sku: sku,
                  type: type.toUpperCase()
                }
              };
        
              axios(config)
                .then(() => {
                  setShowInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: showInfo.monitor4, allmonitors: "sent" });
                })
                .catch(() => {
                  setShowInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: showInfo.monitor4, allmonitors: "error" });
                });

              await delay(1000);
            }
          })();
        } catch (error) {
          setShowInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: showInfo.monitor4, allmonitors: "error" });
        }
  
        setIsSubmitClicked({ monitor1: isSubmitClicked.monitor1, monitor2: isSubmitClicked.monitor2, monitor4: isSubmitClicked.monitor4, allmonitors: false });
      }, 1000);
      
      return;
    }
    
    if (type === 'monitor1') setIsSubmitClicked({ monitor1: true, monitor2: isSubmitClicked.monitor2, monitor4: isSubmitClicked.monitor4 });
    if (type === 'monitor2') setIsSubmitClicked({ monitor1: isSubmitClicked.monitor1, monitor2: true, monitor4: isSubmitClicked.monitor4 });
    if (type === 'monitor4') setIsSubmitClicked({ monitor1: isSubmitClicked.monitor1, monitor2: isSubmitClicked.monitor2, monitor4: true });

    if (sku.includes(' ')) {
      sku = (sku.split(' ')).filter(_ => _ !== '');
    } else {
      sku = [sku];
    }

    setTimeout(() => {
      try {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/api/skus?action=ADD',
          data: {
            sku: sku,
            type: type.toUpperCase()
          }
        };
  
        axios(config)
          .then(() => {
            if (type === 'monitor1') setShowInfo({ monitor1: "sent", monitor2: showInfo.monitor2, monitor4: showInfo.monitor4 });
            if (type === 'monitor2') setShowInfo({ monitor1: showInfo.monitor1, monitor2: "sent", monitor4: showInfo.monitor4 });
            if (type === 'monitor4') setShowInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: "sent" });
          })
          .catch(() => {
            if (type === 'monitor1') setShowInfo({ monitor1: "error", monitor2: showInfo.monitor2, monitor4: showInfo.monitor4 });
            if (type === 'monitor2') setShowInfo({ monitor1: showInfo.monitor1, monitor2: "error", monitor4: showInfo.monitor4 });
            if (type === 'monitor4') setShowInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: "error" });
          });
      } catch (error) {
        if (type === 'monitor1') setShowInfo({ monitor1: "error", monitor2: showInfo.monitor2, monitor4: showInfo.monitor4 });
        if (type === 'monitor2') setShowInfo({ monitor1: showInfo.monitor1, monitor2: "error", monitor4: showInfo.monitor4 });
        if (type === 'monitor4') setShowInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: "error" });
      }

      if (type === 'monitor1') setIsSubmitClicked({ monitor1: false, monitor2: isSubmitClicked.monitor2, monitor4: isSubmitClicked.monitor4 });
      if (type === 'monitor2') setIsSubmitClicked({ monitor1: isSubmitClicked.monitor1, monitor2: false, monitor4: isSubmitClicked.monitor4 });
      if (type === 'monitor4') setIsSubmitClicked({ monitor1: isSubmitClicked.monitor1, monitor2: isSubmitClicked.monitor2, monitor4: false });
    }, 1000);
  };

  const handleDelete = e => {
    e.preventDefault();

    const type = e.target.name;
    let sku = type === "monitor2" ? inputSKU.sku2 : type === "monitor4" ? inputSKU.sku4 : type === "allmonitors" ? inputSKU.allsku : inputSKU.sku1;
    
    if (type === "allmonitors") {
      setIsDeleteClicked({ monitor1: isSubmitClicked.monitor1, monitor2: isSubmitClicked.monitor2, monitor4: isSubmitClicked.monitor4, allmonitors: true });

      setTimeout(() => {
        try {
          const monitors = ["monitor1", "monitor2", "monitor4"];

          const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

          if (sku.includes(' ')) {
            sku = (sku.split(' ')).filter(_ => _ !== '');
          } else {
            sku = [sku];
          }

          (async () => {
            for (const type of monitors) {
              const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: '/api/skus?action=REMOVE',
                data: {
                  sku: sku,
                  type: type.toUpperCase()
                }
              };
        
              axios(config)
                .then(() => {
                  setShowRemoveInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: showInfo.monitor4, allmonitors: "sent" });
                })
                .catch(() => {
                  setShowRemoveInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: showInfo.monitor4, allmonitors: "error" });
                });

              await delay(1000);
            }
          })();
        } catch (error) {
          setShowRemoveInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: showInfo.monitor4, allmonitors: "error" });
        }
  
        setIsDeleteClicked({ monitor1: isSubmitClicked.monitor1, monitor2: isSubmitClicked.monitor2, monitor4: isSubmitClicked.monitor4, allmonitors: false });
      }, 1000);
      
      return;
    }

    if (type === 'monitor1') setIsDeleteClicked({ monitor1: true, monitor2: isSubmitClicked.monitor2, monitor4: isSubmitClicked.monitor4 });
    if (type === 'monitor2') setIsDeleteClicked({ monitor1: isSubmitClicked.monitor1, monitor2: true, monitor4: isSubmitClicked.monitor4 });
    if (type === 'monitor4') setIsDeleteClicked({ monitor1: isSubmitClicked.monitor1, monitor2: isSubmitClicked.monitor2, monitor4: true });

    if (sku.includes(' ')) {
      sku = (sku.split(' ')).filter(_ => _ !== '');
    } else {
      sku = [sku];
    }

    setTimeout(() => {
      try {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/api/skus?action=REMOVE',
          data: {
            sku: sku,
            type: type.toUpperCase()
          }
        };
  
        axios(config)
          .then(() => {
            if (type === 'monitor1') setShowRemoveInfo({ monitor1: "sent", monitor2: showInfo.monitor2, monitor4: showInfo.monitor4 });
            if (type === 'monitor2') setShowRemoveInfo({ monitor1: showInfo.monitor1, monitor2: "sent", monitor4: showInfo.monitor4 });
            if (type === 'monitor4') setShowRemoveInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: "sent" });
          })
          .catch(() => {
            if (type === 'monitor1') setShowRemoveInfo({ monitor1: "error", monitor2: showInfo.monitor2, monitor4: showInfo.monitor4 });
            if (type === 'monitor2') setShowRemoveInfo({ monitor1: showInfo.monitor1, monitor2: "error", monitor4: showInfo.monitor4 });
            if (type === 'monitor4') setShowRemoveInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: "error" });
          });
      } catch (error) {
        if (type === 'monitor1') setShowRemoveInfo({ monitor1: "error", monitor2: showInfo.monitor2, monitor4: showInfo.monitor4 });
        if (type === 'monitor2') setShowRemoveInfo({ monitor1: showInfo.monitor1, monitor2: "error", monitor4: showInfo.monitor4 });
        if (type === 'monitor4') setShowRemoveInfo({ monitor1: showInfo.monitor1, monitor2: showInfo.monitor2, monitor4: "error" });
      }

      if (type === 'monitor1') setIsDeleteClicked({ monitor1: false, monitor2: isSubmitClicked.monitor2, monitor4: isSubmitClicked.monitor4 });
      if (type === 'monitor2') setIsDeleteClicked({ monitor1: isSubmitClicked.monitor1, monitor2: false, monitor4: isSubmitClicked.monitor4 });  
      if (type === 'monitor4') setIsDeleteClicked({ monitor1: isSubmitClicked.monitor1, monitor2: isSubmitClicked.monitor2, monitor4: false });
    }, 1000);
  };

  useEffect(() => {
    setTimeout(async () => {
      setShowInfo({ monitor1: "", monitor2: "" });
    }, 8000);
  }, [showInfo]);

  useEffect(() => {
    setTimeout(async () => {
      setShowRemoveInfo({ monitor1: "", monitor2: "" });
    }, 8000);
  }, [showRemoveInfo]);

  useEffect(() => {
    AOS.init({      
      offset: 300,
      duration: 100,
      easing: 'ease-in-out-quart',
    });
  }, []);
  
  return (
    <div className={SkusCSS["skus"]}>
      <form className={SkusCSS["form-container"]} onSubmit={handleSubmit} method="post" name="allmonitors">
        <label htmlFor="inputName">Enter the SKU (Monitor #1, #2, #4)</label>
        <input type="text" name="inputSKU0" id="inputSKU" onChange={e => setInputSKU({ sku1: inputSKU.sku1, sku2: inputSKU.sku2, sku4: inputSKU.sku4, allsku: e.target.value }) }/>
        <button className={isSubmitClicked.allmonitors ? SkusCSS["spin"] : ""} type="submit">
          {showInfo.allmonitors === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU added successfully</p></div>}
          {showInfo.allmonitors === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Add
          <span className={SkusCSS["spinner"]}></span>
        </button>
        <button
          className={isDeleteClicked.allmonitors ? SkusCSS["spin"] : ""}
          onClick={handleDelete} 
          type="button" 
          name="allmonitors">
          {showRemoveInfo.allmonitors === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU deleted successfully</p></div>}
          {showRemoveInfo.allmonitors === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Remove
        </button>
      </form>

      <form className={SkusCSS["form-container"]} onSubmit={handleSubmit} method="post" name="monitor1">
        <label htmlFor="inputName">Enter the SKU (Monitor #1)</label>
        <input type="text" name="inputSKU1" id="inputSKU" onChange={e => setInputSKU({ sku1: e.target.value, sku2: inputSKU.sku2, sku4: inputSKU.sku4 }) }/>
        <button className={isSubmitClicked.monitor1 ? SkusCSS["spin"] : ""} type="submit">
          {showInfo.monitor1 === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU added successfully</p></div>}
          {showInfo.monitor1 === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Add
          <span className={SkusCSS["spinner"]}></span>
        </button>
        <button 
          className={isDeleteClicked.monitor1 ? SkusCSS["spin"] : ""}
          onClick={handleDelete} 
          type="button" 
          name="monitor1">
          {showRemoveInfo.monitor1 === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU deleted successfully</p></div>}
          {showRemoveInfo.monitor1 === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Remove
        </button>
      </form>

      <form className={SkusCSS["form-container"]} onSubmit={handleSubmit} method="post" name="monitor2">
        <label htmlFor="inputName">Enter the SKU (Monitor #2)</label>
        <input type="text" name="inputSKU2" id="inputSKU" onChange={e => setInputSKU({ sku1: inputSKU.sku1, sku2: e.target.value, sku4: inputSKU.sku4 })}/>
        <button className={isSubmitClicked.monitor2 ? SkusCSS["spin"] : ""} type="submit">
          {showInfo.monitor2 === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU added successfully</p></div>}
          {showInfo.monitor2 === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Add
          <span className={SkusCSS["spinner"]}></span>
        </button>
        <button 
          className={isDeleteClicked.monitor2 ? SkusCSS["spin"] : ""}
          onClick={handleDelete} 
          type="button" 
          name="monitor2">
          {showRemoveInfo.monitor2 === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU deleted successfully</p></div>}
          {showRemoveInfo.monitor2 === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Remove
        </button>
      </form>

      <form className={SkusCSS["form-container"]} onSubmit={handleSubmit} method="post" name="monitor4">
        <label htmlFor="inputName">Enter the SKU (Monitor #4)</label>
        <input type="text" name="inputSKU4" id="inputSKU" onChange={e => setInputSKU({ sku1: inputSKU.sku1, sku2: inputSKU.sku2, sku4: e.target.value })}/>
        <button className={isSubmitClicked.monitor4 ? SkusCSS["spin"] : ""} type="submit">
          {showInfo.monitor4 === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU added successfully</p></div>}
          {showInfo.monitor4 === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Add
          <span className={SkusCSS["spinner"]}></span>
        </button>
        <button 
          className={isDeleteClicked.monitor4 ? SkusCSS["spin"] : ""}
          onClick={handleDelete} 
          type="button" 
          name="monitor4">
          {showRemoveInfo.monitor4 === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU deleted successfully</p></div>}
          {showRemoveInfo.monitor4 === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Remove
        </button>
      </form>

      <form className={SkusCSS["form-container"]} onSubmit={handleSubmit} method="post" name="adidashot1">
        <label htmlFor="inputName">Enter the SKU (Adidas Hot #1)</label>
        <input type="text" name="inputSKU4" id="inputSKU" onChange={e => setInputSKU({ sku1: inputSKU.sku1, sku2: inputSKU.sku2, sku4: e.target.value })}/>
        <button className={isSubmitClicked.adidashot1 ? SkusCSS["spin"] : ""} type="submit">
          {showInfo.adidashot1 === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU added successfully</p></div>}
          {showInfo.adidashot1 === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Add
          <span className={SkusCSS["spinner"]}></span>
        </button>
        <button 
          className={isDeleteClicked.adidashot1 ? SkusCSS["spin"] : ""}
          onClick={handleDelete} 
          type="button" 
          name="adidashot1">
          {showRemoveInfo.adidashot1 === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU deleted successfully</p></div>}
          {showRemoveInfo.adidashot1 === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Remove
        </button>
      </form>
    </div>
  );
}

export default ImageMonitor;